.day-group-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: hsl(0, 0%, 20%);
  margin-bottom: 10px;
}

.message-note__title {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(13, 54, 101, 0.8);
  margin-top: 5px;
}

.message-note__message {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0d3665;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.notification-note__message label{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0d3665;
}

.notification-note__message span{
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0d3665;
  margin-left: 5px;
}

.message-note_icon-inner {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lighten(#0d3665, 8);
  border-radius: 4px;
  color: #fff;
}

.add-notes-link {
  cursor: pointer;
  color: #0d3665;
  font-size: 18px;
  margin-bottom: 8px;
}

.message-note__icon {
  margin-right: 10px;
}

.notes-day-group {
  margin-bottom: 25px;
}

.day-group-content {
  padding: 12px;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.message-note {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &:last-child {
    margin-bottom: 0;
  }
}

.notification-note__message {
  margin-bottom: -5px;
}

