.user-details-tab {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  padding: 0px 25px;
}

.tabItem {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 30px;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  color: rgba(13, 54, 101, 0.6);
}

.active.tabItem {
  color: #0d3665;
}

.active.tabItem:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgb(0,161,207);
  bottom: -2px;
  left: 0;
}

.tab-content {
  border-top: 2px solid rgb(241, 241, 241);
  background-color: #fff;
}

.tab-wrapper-outer {
  border-radius: 3px;
  box-shadow: 0 2px 0 0 #e7e7e7;
  border: solid 1px #e9e9e9;
}

.tab-content-dashboard, .tab-content-cases {
  padding: 25px;
}
.tab-content-cases {
  padding-top: 0;
}

.messaging-inner {
  position: relative;
}

.message-count-tab {
  position: absolute;
  display: block;
  top: -6px;
  right: -9px;
  width: 18px;
  height: 18px;
  background: rgba(255, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  border-radius: 100%;
}
