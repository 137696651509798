.compare-holder {
  display: flex;
}

.compare-holder > div {
  margin-right: 5px;
}

// charts

.apexcharts-xaxis-label:nth-last-child(2) {
  transform: translateX(-20px)
}

.chart-heading {
  font-size: 17px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 0;
  position: absolute;
  left: -78px;
}

.single-chart {
  padding-bottom: 20px;
  padding-top: 20px;
}

.single-chart svg {
  overflow: visible;
}

.chart-header {
  margin-bottom: 10px;
}

.right-block {
  .single-chart, .chart-header {
    left: 100px;
    position: relative;
  }
}

.chart-navigation-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.chart-navigation-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
