.text-message .doctor-message .message-item__content__body {
  background-color: #2b88cb;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  border-top-right-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

.text-message .user-message .message-item__content__body {
  background-color: rgba(85, 133, 143, 0.15);
  padding: 20px;
  border-radius: 10px;
  border-top-left-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: rgba(27, 27, 27, 0.8);
}
