.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-group.has-search {
  display: flex;
  flex: 1;
  margin-right: 5px;
}

.search-bar {
  display: flex;
  flex: 1;
}

button.btn.btn-primary.clear-btn {
  margin-left: 5px;
}

span.search-term-text {
  font-weight: bold;
}

.search-term-result-text {
  padding: 10px;
  padding-left: 15px;
  padding-top: 0;
}

.page-count-text-heading {
  padding-left: 15px;
  padding-right: 15px;
  text-align: Center;
}

.invitation-pending-hint {
  color: #0d3665;
  font-size: 0.9em;
}

// single user changes
.message-patient-btn {
  cursor: pointer;
  i {
      font-size: 12px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  margin-left: 5px;
  background-color: #ffffff;
  color: $primary-color;
}

.patient-details-and-message-button {
  display: flex;
  margin-top: 10px;
}

.user-info-box {
  padding-left: 10px;
  margin-bottom: 15px;
}

// severity label
.last-severity-badge {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  border-radius: 100%;
  font-size: 10px;
}

.user-list__last-severity-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.severity-badge-4 {
  background-color: red;
  color: #ffffff;
}

.severity-badge-3 {
  background: yellow;
  color: #000000;
}

.severity-badge-2 {
  background-color: #ADFF2F;
  color: #000000;
}

.severity-badge-1, .severity-badge-0 {
  background: #9A9D48;
  color: #ffffff;
}

/* severity badge */
@media screen and (max-width: 767px) {
    .user-list__last-severity-check.user-small-cell {
      display: flex;
      justify-content: end;
    }
    
    .last-severity-badge.severity-badge-2 {
      height: auto;
      background: transparent;
      color: black;
    }
    
    .list-group-item .mobile-only-th {
      margin-left: 0;
    }
    
    .last-severity-badge {
      height: auto;
      border-radius: 4px;
      margin-left: 4px;
    }
}
