.sub-category-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.switch-holder {
  display: block;
  margin-top: 15px;
}

.tracker-top-holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tracker-date-selector-wrapper {
  margin-bottom: 10px;
}

.tracker-activity-time-selector {
  margin-bottom: 10px;
}

.tracker-top-holder-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-done-logging {
  margin-left: auto;
}

.tracker-header-bottom {
  display: flex;
  flex-direction: row;
}

.tip-holder {
  width: 80%;
}

.switch-holder {
  width: 20%;
  text-align: right;
}
.switch-holder .tibot-button {
  display: inline-block;
}
.edit-icon-wrapper {
  cursor: pointer;
  display: inline-block;
  font-size: 1.4em;
  margin-bottom: 10px;
}

.tip-holder {
  display: flex;
  align-items: center;
  justify-content: left;
}

.edit-screen-button-wrapper {
  text-align: right;
}

.select-date-text {
  text-align: center;
  margin-bottom: 5px;
}

.edit-screen-wrapper, .log-screen-wrapper {
  padding-top: 7px;
  padding-bottom: 7px;
  height: 165px;
}

.edit-screen-wrapper {
  display: flex;
  flex-direction: column;
}

.tracker-top-holder-edit-only {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.tracker-top-title.tibot-second-heading {
  font-weight: Normal;
  margin-bottom: 10px;
  font-size: 1.5em;
}



@media screen and (max-width: 767px) {
  
  .eczemaless-tracker {
      .tibot-button, .tibot-button:hover, .tibot-button:active {
          padding: 7px 11px;
      }
  }
}

/**** overrides ****/

.tibot-list-group-heading.list-group-item {
  display: flex;
  justify-content: space-between;
}

.tibot-list-group-heading.list-group-item .list-group-arrow {
  background: transparent;
}

.tracker-category {
  margin-top: 25px;
  margin-bottom: 25px;
}

.unconnected-care-management {
  max-width: none;
  margin: 0 auto;
}

.list-group-arrow i {
  color: #0d3665;
}

.care-tracker--user {
  margin: 0 auto;
}

.suggest-care-activity-headline {
  text-align: center;
}

.care-tracker-wrapper-div {
  padding-top: 5px;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.care-activity-tracker-unconnected {
  margin-top: 20px;
}

.unconnected-user-email {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .care-tracker-wrapper-div {
    background-color: #ffffff;
  }
}

// new changes
.single-activity-wrapper {
  position: relative;
}

.started-by-doctor-badge {
  position: absolute;
  right: 21px;
  top: 0;
  font-size: 9.5px;
  color: #0d3665;
}


/* new design */
.suggest-care-activity-headline {
  margin-bottom: 25px;
}

.color-selector-wrapper {
  max-width: 400px;
  margin: 0 auto;
}

.tibot-list-group-heading.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: whitesmoke;
}

.category-row-dropdown {
  width: 450px;
  margin-left: 10px;
  margin-right: 10px;
}

.single-activity-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.sub-category-item {
  flex: 1;
}

.row-dropdown {
  width: 250px;
  margin-right: 10px;
}

.activity-label {
  margin-right: 10px;
}

.activity-label {
  width: 220px;
}

.preset-selector-wrapper {
  display: flex;
  flex-direction: row;
}


@media screen and (max-width: 767.98px) {
  .category-row-dropdown {
    width: 350px;
  }
}

@media screen and (max-width: 575.98px) {
  .category-row-dropdown {
    width: 200px;
  }
  .single-activity-wrapper.activity-wrapper {
    padding: 0.75rem;
  }
  .activity-wrapper .sub-category-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .activity-wrapper .sub-category-item > * {
    margin-top:  10px;
  }
}

/* preset selector formatting */
.preset-treatment-wrapper {
  display: flex;
}

.preset-empty-div {
  flex: 1;
}

.color-selector-wrapper {
  flex: 1;
}

.preset-selector-wrapper {
  flex: 1;
}

.color-selector-wrapper.preset-dd {
  margin-left: 15px;
  margin-right: 40px;
}

@media screen and (max-width: 768px) {
  .preset-treatment-wrapper {
    display: block;
  }

  .preset-empty-div {
      display: none;
  }

  .color-selector-wrapper {
      margin-bottom: 10px;
  }

  .preset-selector-wrapper {
      display: flex;
      flex: 1;
  }

  .color-selector-wrapper.preset-dd {
      margin-left: 0;
      margin-bottom: 0;
  }
}

.preset-name-label {
  margin-top: 5px;
}

.treatment-plan-name-label {
  margin-right: 10px;
  margin-top: 5px;
}

.add-treatment-plan {
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
}
