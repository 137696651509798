div#root {
  min-height: 100vh;
}

div#root, .main-wrapper, .sidebar-and-component, .template-component-wrapper, .tab-wrapper-outer, .user-details-tab-items-wrapper,
.tab-content-wrapper, .user-area-component, .activeTabContent.tab-content-inner, .tab-content, .message-wrapper, .message-list-wrapper-items {
  @include flex-full-height;
}

.doctor-notes {
  margin-top: 35px;
}

.review-data-type {
  text-transform: capitalize;
}

.chart-tab-loader {
  position: absolute;
  top: 133px;
  left: 330px;
}
