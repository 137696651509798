// .message-list {
//   border-radius: 3px;
//   box-shadow: 0 2px 0 0 #e7e7e7;
//   border: solid 1px #e9e9e9;
//   background-color: #ffffff;
// }

.inbox-user-image {
  border-radius: 100%;
  max-width: 40px;
  max-height: auto;
}

// .message-list-table td {
//   border-top: 1px solid rgba(51, 51, 51, 0.08);
//   padding: 10px;
// }

// .message-list-table {
//   width: 100%;
// }
// .message-list-table tr:first-child td {
//   border-bottom: 0;
// }

.unread {
  font-weight: bold;
}
.message-list__avatar {
  text-align: center;
}

.refresh-icon {
  color: rgba(51, 51, 51, 0.5);
  cursor: pointer;
  &:hover, &:focus {
    color: #000;
  }
}

// .top-cell {
//   border-top: 0 !important;
// }

.message-list {
  background: #fff;
}

.ascend-descend-icon-wrapper {
  margin-right: 5px;
}

.message-list-table.table td {
  vertical-align: middle;
}