@import './user-tab';
@import './case-list';
@import './careplan-tab';
@import './invite-patient';
@import './notes';
@import './inbox';

body {
  background-color: #f3f3f3;
}

.template-component-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 20px;
  background-color: #f3f3f3;
  overflow-x: scroll;
}

// screen header
.screen-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.screen-header__heading {
  color: #31455c;
  font-size: 24px;
}

// cta button
.cta-button-primary {
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: 0 4px 4px 0 rgba(6, 112, 135, 0.1);
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include setBGandHover(#0d3665);
}

.cta-button-primary__icon {
  margin-right: 10px;
}

// screencontent
.screen-content {
  border-radius: 3px;
  box-shadow: 0 0 14px 0 rgba(9, 41, 59, 0.05), 0 0 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

// small cards in patient details page
.small-card {
  border-radius: 3px;
  box-shadow: 0 2px 0 0 #e2e2e2;
  background-color: #ffffff;
  padding: 16px;
}

.patient-details-card {
  max-width: 220px;
}

.card-patient-details-header {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: rgba(13, 54, 101, 0.5);
}

.card-patient-details-info {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d3665;
  margin-top: 8px;
}

.card-timer-header {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d3665;
  margin-top: 8px;
}

.card-timer-label {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: rgba(13, 54, 101, 0.5);
}

.card-timer-total {
  margin-right: 15px;
  width: 250px;
}

.card-timer-play-pause {
  width: 350px;
}

.timers-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.card-mult-col {
  display: flex;
  flex-direction: row;
}

.icon-image {
  cursor: pointer;
}

.icon-image img {
  max-width: 40px;
}

.timer-action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.timer-action-buttons .icon-image {
  margin-left: 3px;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

