.tab-heading-wrapper {
  display: flex; 
  border-bottom: 1px solid #ccc; 
}

.tabItem {
  cursor: pointer; 
  padding: 10px; 
  margin-right: 10px;
}

.tab-content-wrapper {
  margin-top: 20px;
}

.tab-content-inner {
  display: none; 
}

.tab-content-inner.activeTabContent {
  display: block; 
}
