.report-section {
    padding: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding-top: 15px;
}

.report-section h3 {
    margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
    .report-section {
        padding: 5px;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        background-color: #f9f9f9;
        padding-top: 5px;
    }
}
