.careplan-tab {
  .tab-wrapper {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 10px;
}

.tab-wrapper .tab-content {
    margin-top: 15px;
}

.nav-link {
    cursor: pointer;
}

.tab-content {
    margin-top: 9px;
}

.user-details-tab {
    border: 0;
}

.active.user-details-tab-item.nav-link {
    border: 0;
}

.user-details-tab-item {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0;
    &:hover {
        border: 0;
        border-radius: 0 !important;
    }
    margin-top: 7px;
}

.tab-inner-button {
    padding: 10px 20px;
    background: whitesmoke;
    margin-right: 15px;
    border-radius: 30px;
}

.tab-inner-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-inner-button i {
    margin-right: 7px;
}

.active.user-details-tab-item > .tab-inner-button {
    background: #0d3665;
    color: #fff;
}

.nowrap-nav {
    flex-wrap: nowrap;
}

.no-max-width {
    max-width: none;
}

.tab-wrapper-unconnected {
    max-width: none;
}

.nav-custom-styles {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0;
    padding-bottom: 10px;
}

.nav-custom-styles .nav-item a.nav-link {
    border: 1px solid #0d3665;
    border-radius: 0;
}

.nav-custom-styles .nav-item a.nav-link.active {
    background-color: #0d3665;
    color: white;
}

.nav-custom-styles .nav-item {
    &:nth-child(1) .nav-link {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
    }
    &:nth-child(2) .nav-link {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }
}

@media screen and (max-width: 767px) {
    .tab-inner-button i {
        margin-right: 0;
    }
    
    .tab-inner-button span {
        display: none;
    }
}

.tab-content {
  background: transparent;
  border-top: 0;
}

}