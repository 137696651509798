// details block
.details-block {
  border-radius: 3px;
  border: solid 1px rgba(204, 204, 204, 0.7);
  background-color: #ffffff;
  height: 230px;
}

.patient-details-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
}

img.patient-avatar-details {
  max-width: 80px;
  border-radius: 100%;
  margin-bottom: 8px;
}

.patient-details-name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d3665;
  margin-bottom: 17px;
}

.patient-details-age {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.patient-details-avatar {
  text-align: center;
}

.details-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.details-field__title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  color: rgba(13, 54, 101, 0.5);
  margin-right: 20px;
}

.details-field__info {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d3665;
  word-break: break-all;
}

.patient-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.patient-details-avatar {
  text-align: center;
  padding: 30px;
  padding-right: 15px;
  border-right: 1px solid #e5e5e5;
}

.patient-details-info-list {
  flex: 1;
  padding: 30px;
  padding-left: 15px;
}

.details-field {
  margin-bottom: 10px;
}



// download report changes

.download-report-dd {
  border-radius: 3px;
  background: #3d5e84;
}

.download-report-dd {
  border-radius: 3px;
  background: #3d5e84;
  color: #fff;
  font-size: 13px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-color: #3d5e84;
}

body .download-report-dd, body .download-report-dd.active {
  &:hover, &:focus{
    background: #3d5e84 !important;
    color: #fff !important;
  }
}

.buttons---btn-border---1O58b:active, .buttons---btn-border---1O58b.buttons---active---2-a32, .buttons---open---1ju75 > .buttons---dropdown-toggle---vMtjL.buttons---btn-border---1O58b {
  background: #3d5e84 !important;
  color: #fff !important;
}
// user statistics
.user-stats-result {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d3665;
  margin-top: 30px;
  margin-left: 50px;
}

.stats-single-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.user-stats-title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: rgba(13, 54, 101, 0.5);
  margin-left: 50px;
}

.stats-row {
  display: flex;
}

.stats-single-element {
  width: 175px;
  height: 100px;
}

.user-stats.details-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 26px 0px;
  max-width: 550px;
}

.stats-second-row .stats-single-element {
  border-bottom-color: transparent !important;
}

// user details table cases
.case-image-inner {
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.case-image-inner img {
  max-width: 100%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.user-stats.single-case {
  max-width: 435px;
  //border-left: 1px solid lightgrey;
  //border-top: 1px solid lightgrey;
  //border-right: 1px solid lightgrey;
  //border-bottom: 1px solid lightgrey;
}

.case-single-element {
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  width: 281px;
  height: 115px;
  //padding-left: 30px;
}

.border-left {
  border-left: 1px solid lightgrey;
}

.border-top {
  border-top: 1px solid lightgrey;
}

.border-right {
  border-right: 1px solid lightgrey;
}

.border-bottom {
  border-bottom: 1px solid lightgrey;
}

.score-icon label {
  margin-left: 20px;
  width: 25px;
  font-size: 12px;
  text-align: center;
}

.scoreOne {
  background-color: #fff2b3;
}

.scoreTwo {
  background-color: #ffcc66;
}

.scoreThree {
  background-color: #ffb366;
}

.scoreFour {
  background-color: #ff6666;
}

.scoreFive {
  background-color: #ff3333;
}

.chat-log-icon {
  cursor: pointer;
}

.chat-log {
  border-bottom: 1px solid lightgrey;
}

.chat-log__question {
  padding-top: 10px;
  font-size: 14px;
}

.chat-log__answer {
  padding-bottom: 10px;
  font-size: 14px;
}

.chat-log__qa {
  color: #00a7cb;
  font-size: 14px;
  margin-right: 10px;
}

div.modal-body div:last-of-type {
  border-bottom: none;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-wrapper > div {
  margin: 10px;
  padding: 20px;
}


.disconnect-button {
  background-color: #3d5e84;
}
