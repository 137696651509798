.patient-name {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  text-align: left;
  color: rgba(13, 54, 101, 0.8);
  font-size: 1.4em;
  text-transform: uppercase;
}

.table-heading, .lhs-section-heading {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.54px;
  text-align: left;
  color: rgba(51, 51, 51, 0.8);
  font-size: 1.2em;
}

.consultation-screen-heading {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: rgba(51, 51, 51, 0.8);
  font-size: 1.7em;
  margin-left: 15px;
  margin-bottom: 16px;
}

.consultation-case-images {
  display: flex;
  flex-wrap: wrap;
}

.input-heading {
  font-size: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  text-align: left;
  color: rgba(51, 51, 51, 0.8);
}

.select-template-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.select-template-text {
  width: 200px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(51, 51, 51, 0.8);
  font-size: 0.9em;
  text-transform: uppercase;
}

.notes-input {
  padding: 12px 18px 3px 12px;
  border-radius: 3px;
  background-color: #eaeaea !important;
}

table.table {
  .info-row-key, .info-row-value {
    font-weight: 600;
    font-size: 15px;
    border-top-color: rgba(0, 0, 0, 0.08);
    padding: 0.5em;
    padding-right: 0.75em;
    vertical-align: middle;
    padding-left: 0;
  }
  .info-row-key {
    text-transform: uppercase;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: left;
    color: rgba(13, 54, 101, 0.5);
  }
  .info-row-value {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(48, 48, 48, 0.9);
  }
}  

.care-routine-table-heading {
  color: #000000;
  margin-bottom: auto;
}

.activities-list {
  margin-bottom: 6px;
}

.lhs-section {
  margin-bottom: 20px;
}
.setup-care-routine-link {
  margin-bottom: 5px;
  display: block;
}

.consultation-container {
  margin-top: 20px;
}

@media screen and (min-width: 1380px) {
  body .consultation-container {
    margin-left: 25px !important;
  }
}

.submit-button-holder {
  display: block;
  padding-bottom: 20px;
}

.activity-consultation-pill {
  font-weight: normal;
  border: 1px solid #c2c2c2;
  display: inline-block;
  padding: 5px;
  margin-right: 7px;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.close-icon-activity {
  position: absolute;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  top: -9px;
  right: -6px;
  background: #fff;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activity-consultation-pill {position: relative;}
