.case-list-v2 {
  .case-list-heading-item {
    border: 0;
  }
  .case-list__row > div {
      border: 0;
  }
  .list-group-item {
    border-left:0;
    border-right: 0;
  }

  li.case-list-heading-item {
    border: 0;
  }
  .case-list__row > div {
      border: 0;
  }
  li.list-group-item:nth-child(even) {
      /* background: red; */
      box-shadow: 0 1px 0 0 rgba(221, 228, 229, 0.5);
      background-color: #f9fcfd;
  }
}

.users-list-table-v2 tbody td {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(51, 51, 51, 0.9);
}

.users-list-table-v2 thead td {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  text-transform: uppercase;
}

.user-case-list-table thead tr td {
  border-top: 0;
  padding-top: 20px;
  padding-bottom: 19px;
}

