.user-case-area {
  max-width: 600px;
  margin: 0 auto;
}

.user-case-date {
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
}

.user-case-result-wrapper {
  margin-bottom: 10px;
}

.user-case-result-wrapper span {
  color: #0d3665;
  font-weight: bold;
}

.user-case-area h4 {
  color: #0d3665;
}

.history-insights-wrapper {
  // display: flex;
  // justify-content: flex-start;
  overflow-x: scroll;
  padding-top: 20px;
  width: 1550px;
  display: grid;
  grid-template-columns: 620px 890px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-navigation-button {
  background-color: #f7f7f7;
  border: solid 1px #f7f7f7;
  cursor: pointer;
  padding: 14px 16px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: gray;
}

.chart-tabs {
  overflow: hidden;
}

.chart-tabs button {
  background: none;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-align: left;
  color: gray;
  border: none;
  outline: none;
}

.chart-title-holder {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #171725;
  font-size: 1em;
  font-weight: bold;
}

.apexcharts-menu-icon {
  display: none;
}

.right-block {
  padding-right: 60px;
}

button.chart-navigation-tab.active {
  color: #00a7cb;
  border-bottom: 2px solid #00a7cb;
  outline: none;
}

button.chart-navigation-button.active {
  outline: none;
  border: 1px solid #0066ff;
  color: #0066ff;
}
