$menuBackground: #0d3665;

.sidebar-and-component {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.vertical-menu-inner {
  height: calc(100vh - 71px);
  position: fixed;
  width: inherit;
  padding-top: 30px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: $menuBackground;
}

.vertical-menu {
  width: 220px;
  position: relative;
}

@include for-big-desktop-up {
  .vertical-menu {
    width: 290px;
  }
}

.vertical-menu-items {
  margin-left: 0;
  padding-left: 0;
}

.vertical-menu-items a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  margin: 20px;
  margin-top: 0;

  .custom-svg-icon {
    margin-right: 10px;
    width: 20px;
  }

  &:hover {
    color: #fff;
  }
}

a.tibot-menu-item-v2:hover .custom-svg-icon svg path, a.tibot-menu-item-v2.active .custom-svg-icon svg path {
  fill: #fff;
  fill-opacity: 1;
}

a.tibot-menu-item-v2 .custom-svg-icon svg path{
  fill: #fff;
  fill-opacity: 0.5;
}

.tibot-menu-item-v2 {
  padding: 10px;
  border-radius: 4px;
}

.tibot-menu-item-v2:hover, .tibot-menu-item-v2.active {
  padding: 10px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
}

.tibot-menu-item-v2:hover, .tibot-menu-item-v2.active {
  color: #fff !important;
}

.vertical-menu-inbox-count {
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.13);
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
