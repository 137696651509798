$primary-color: #0d3665;
$body-color: #212529;
// mixins
@mixin flex-full-height {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@mixin reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

// fix: cleared input leaves a red border in required fields in firefox
input:required {
  box-shadow: none;
}

body {
  font-family: 'Inter', sans-serif;
}

.tibot-button, .tibot-button:hover, .tibot-button:active {
  background: $primary-color;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: .4em;
}

.tibot-button:hover {
  background-color: darken( $primary-color, 10)
}

.temp-hide {
  display: none !important;
}

// generic layout
.flex-parent {
  display: flex;
}

.flex-parent-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
