.insight-cards {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.insight-card {
    margin-right: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
}

.insight-card-left {
    margin-right: 25px;
}

.insight-card__title {
    font-weight: bold;
    font-size: 1.4em;
}

.insight-card__totalInfo {
    font-weight: 400;
    font-size: 0.9em;
}

.insight-card__icon {
    font-size: 25px;
    width: 55px;
    height: 55px;
    background-color: rgb(77, 124, 189);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}
.insight-card__subtitle {
    color: rgba(255, 255, 255, 0.8);
}
.insight-card__totalInfo {
    color: rgba(255, 255, 255, 0.6);
}
