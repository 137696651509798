@import 'message-notification';

.message-list .list-group-item {
  padding: 0;
}

.message-list .doctor-unread {
  font-weight: bold;
}

.message-list .list-group-item:hover {
  background: whitesmoke;
}

.message-list__image img {
  width: 30px;
  height: auto;
  border-radius: 4px;
}

.message-list__row > div {
  display: inline-block;
  margin-right: 20px;
}

.message-list .message-list__link {
  padding: .5rem 1.25rem;
  display: block;
  color: rgb(20, 20, 20);
}

.message-list__date {
  background-color: #0d3665;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 10px;
}

.messageBold {
  font-weight: bold;
}

.message-list__content {
  color: grey;
}

