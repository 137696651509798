.logo-holder-v2 img {
  max-width: 120px;
}
.v2-menu-wrapper {
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: white;
  padding-left: 2em;
  padding-right: 2em;
  box-shadow: 0 2px 0 0 rgba(125, 165, 174, 0.2);
}

.header-content-holder {
  display: flex;
  flex-direction: row;
}

.header-profile-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-photo-header {
  max-width: 40px;
  border-radius: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.v2-menu-wrapper {
  display: flex;
  align-items: center;
}

.vertical-divider {
    width: 1px;
    background-color: rgba(125, 165, 174, 0.2);
    display: flex;
}

.user-photo-section {
  margin-right: 20px;
}

// profile dropdown list
.profile-dropdown {
  position: relative;
}

.dropdown-menu::before {
  border-bottom: 9px solid rgba(0, 0, 0, 0.2);
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  right: 20px;
  position: absolute;
  top: -8px;
}
.dropdown-menu::after {
  border-bottom: 8px solid #FFFFFF;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  right: 20px;
  position: absolute;
  top: -7px;
}

.name-dd-ul {
  top: 40px;
  height: 0;
  transition: height 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.name-dd-ul.active {
  height: 80px;
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}

// notification
.notification-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
