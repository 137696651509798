.error-message-invite strong {
  color: red;
}

.error-message-invite {
  color: red;
}

.error-message-invite, .success-message-invite {
  margin-top: 10px;
}
