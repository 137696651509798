@mixin setBGandHover ($colorName) {
  background: $colorName;
  transition: background 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: darken($colorName, 10%);
  }
}

@mixin setColorAndHover ($colorName) {
  color: $colorName;
  cursor: pointer;
  transition: color 0.5s ease;
  &:hover {
    color: darken($colorName, 10%);
  }
}

// media queries
@mixin for-big-desktop-up {
  @media (min-width: 1380px) {
    @content;
  }
}
