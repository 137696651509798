.page-navigator-wrapper {
  text-align: center;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-navigator-wrapper input[type="number"] {
  height: 34px;
  border-radius: 5px;
  border: 1px solid #0d3665;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  width: 71px;
}

.next-prev-btn.disabled {
  visibility: hidden;
}

.cover-loader {
  position: relative;
}

.cover-loader::after {
  position: absolute;
  content: '';
  background: rgba(21,161,202,0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
