.users-header {
  padding: 18px;
}

.content-list-group-item:nth-child(odd) {
  background-color: #f9fcfd;
}

.iga-badge {
  padding: 2px;
  border-radius: 3px;
  border: solid 1px rgba(32, 161, 189, 0.15);
  font-size: 13px;
  padding: 5px;
  display: inline-block;
}

.iga-0 {
  color: #2bb082;
  background-color: lighten(#2bb082, 50);
}

.iga-1 {
  color: #20a1bd;
  background-color: lighten(#20a1bd, 50);
}

.iga-2 {
  color: darken(#20a1bd, 10);
  background-color: lighten(#20a1bd, 50);

}

.iga-3 {
  color: #ff9931;
  background-color: lighten(#ff9931, 50);
}

.iga-4 {
  color: red;
  background-color: lighten(red, 40);
}

// table design

.users-list-v2 {
  
  thead {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
  }

  tbody {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(51, 51, 51, 0.8);
  }

  td {
    border-right: 1px solid #dee2e6;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  td:last-child {
      border-right: 0;
  }

  .table {
    margin-bottom: 0;
  }

  tr td:first-child {
    padding-left: 19px;
  }

}

.cursor-pointer {
  cursor: pointer;
}

.list-page-heading-wrapper {
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.page-count-text-heading {
  margin-bottom: auto;
  margin-top: auto;
}
