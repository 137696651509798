// .message-input {
//   margin-top: 50px;
// }


// .message-input__input-row {
  //   display: flex;
  //   align-items: baseline;
  // }
  
  // .message-input__textarea {
    //   width: 100%;
    //   border: 0;
    //   border-bottom: 1px solid rgb(128, 128, 128);
    //   display: block;
    //   min-height: 30px !important;
    //   margin-bottom: 5px;
    // }
    
    // .message-input__textarea:focus {
      //   border: 0;
      //   border-bottom: 1px solid rgb(128, 128, 128);
      //   outline-color: transparent;
      // }
      
      // .message-input__input-image-icon svg {
        //   color: grey;
        //   width: 25px;
        //   height: 25px;
        //   cursor: pointer;
        // }
        
        // .message-input__send svg {
          //   color: #ffffff;
          //   cursor: pointer;
          //   width: 30px;
          //   height: 30px;
          //   text-align: center;
          // }
          
          // .message-input__send {
            //   background: $primary-color;
            //   width: 40px;
            //   height: 40px;
            //   border-radius: 100%;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            // }
            
.message-input {
  label {
    margin-bottom: 0;
  }
}           
.message-input__chat-image-upload-input {
  display: none;
}

.message-input-photo-upload {
  padding-left: 10px;
}

.message-input-box.message-input__input-row {
  display: flex;
  // height: 55px;
  border-radius: 8px;
  border: solid 1px rgba(13, 54, 101, 0.2);
  background-color: rgba(13, 54, 101, 0.05);
  justify-content: space-between;
  align-items: center;
}

.message-input-text-input {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 6px;
}

textarea.message-input__textarea {
  width: 100%;
  border: 0 !important;
  background-color: transparent;
  &:focus {
    outline: thin dotted;
  }
}

.message-input__send {
  padding-right: 20px;
  transform: rotate(45deg);
  margin-top: 12px;
  cursor: pointer;
}
