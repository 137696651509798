.stats-wrapper {
  max-width: 530px;
  margin: 0 auto;
}

h4.stats-heading {
  font-size: 19px;
  color: #0d3665;
}

.statbox {
  text-align: center;
  margin-bottom: 24px;
}

.statbox-count {
  font-weight: bold;
  color: #007a9e;
  font-size: 45px;
}