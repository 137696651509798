.badge-button {
    cursor: pointer;
    background-color: $primary-color;
    color: white;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
    &:hover {
        background-color: darken($primary-color, 10);
    }
}

