@import 'globals';
@import 'case-list';
@import 'menu';
@import 'square-spinner';
@import 'tibot-full-screen-loader';
@import 'bootstrap-override';
@import 'doctor-history-report';
@import 'single-case';
@import 'nav';
@import 'cases-page';
@import 'load-awesome-clip';
@import 'loader-button';
@import 'Message/message';
@import 'Message-list/message-list';
@import 'Atoms/Atoms';
@import 'dashboard';
@import 'single-case-user';
@import 'users';
@import 'cases';
@import 'care-activities';
@import 'invite-patient';
@import 'user';
@import './Molecules/Molecules';
@import 'tab';
@import './customizations';

// v2
@import './v2/mixins';
@import './v2/navbar';
@import './v2/verticalnav';
@import './v2/sitewide';
@import './v2/users';
@import './v2/user-details';

html {
  overflow-y: scroll;
}

.main-wrapper {
  padding-bottom: 25px;
}

.doctorEasiHeader{
  width: 100%;
  color: #8f8f8f;
  background-color: #f0f0f0;
  max-width: 100%;
  padding: 11px 14px 8px;
  border-top: 1px solid lightGray;
  border-left: 1px solid lightGray;
  border-right: 1px solid lightGray;
  border-radius: 10px 10px 0px 0px;
}

.doctorEasiBodySection{
  border: 1px solid lightgray;
  border-radius: 0px 0px 10px 10px;
}

.doctorEasiSubSection{
  border: 1px solid lightGray;
  padding: 30px;
}

.doctorEasiOptionSection{
  margin: 0px 4px;
  border-bottom: 1px solid #ddd;
}

.score-selector {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 230px;
  overflow: hidden;
  background: #F0F0F0;
  font-family: Arial, sans-serif;
}

.score-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #595959;
}

.score-item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.score-item-selected {
  background-color: #0D3665;
  color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.score-item:hover {
  background-color: #e0dede;
}

.score-value {
  font-weight: bold;
}

.select-result {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  text-align: center;
  align-items: center;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.result-item {
  box-sizing: border-box;
  border-radius: 7px;
  padding: 75px 0 0;
  flex: 0 0 48%;
  text-align: center;
  background: radial-gradient(50% 50% at 50% 50%, #1B2E6F 0%, #27396D 100%);
  overflow: hidden;
  color: #FFFFFF;
}

.result-item h5 {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.5;
}

.scale-res {
  position: relative;
}

.scale-res p {
  background: #27396D;
  box-shadow: 0px -11px 28px #00000019;
  margin: 0;
  padding: 16px 10px;
  font-size: 20px;
}
.scale-res span.pill {
  margin-left: 4px;
}

.cent-block {
  width: 100%;
  text-align: center;
  padding-top: 70px;
  position: absolute;
}

.result {
  font-weight: 600;
  font-size: 25.1461px;
  line-height: 29px;
}

.result span {
  font-size: 56.0952px;
  line-height: 29px;
  /* or 52% */
  color: #FF553E;
  margin-right: 6px;
}

.pill {
  display: inline-block;
  padding: 3px 5px 1px;
  color: #fff;
  font-weight: 600;
  line-height: 19px;
  background: #232e4f;
  border-radius: 4px;
  font-size: 17px;
}

.pill.clear {
  background: #54F21C;
  color: #1E2C54;
}

.pill.almost_Clear {
  background: #C7F21C;
  color: #1E2C54;
}

.pill.mild {
  background: #F2D01C;
  color: #1E2C54;
}

.pill.moderate {
  background: #FFA133;
  color: #1E2C54;
}

.pill.severe {
  background: #FF553E;
}

.pill.very_severe {
  background: #E92C4E;
}

table.thorough-table {
  margin: 0;
  padding: 0;
  border-spacing: 0;
  /* Removes the cell spacing via CSS */
  border-collapse: collapse;
  border-radius: 6px;
  overflow: hidden;
  font-family: 'Hind';
  font-size: 16px;
  padding: 16px 0 0;
  flex: 0 0 48%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

table.thorough-table tr {
  margin: 0;
  padding: 0;
}

table.thorough-table tr td {
  padding: 11px 15px;
  color: #fff;
  margin: 0;
  text-align: center;
}

table th.span2 {
  width: 45%;
}

table th.span1 {
  width: 20%;
}

table.thorough-table tr td:first-child {
  text-align: left;
}

table.thorough-table thead th:first-child {
  text-align: left;
}

table.thorough-table thead th {
  background-color: #27396D;
  color: #fff;
  text-align: center;
  padding: 12px 15px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
}

table.thorough-table tr:nth-child(odd) td {
  background-color: #27396D;
}

table.thorough-table tr:nth-child(even) td {
  background-color: #2D4280;
}

table.thorough-table tr.total td {
  background: #112B4A;
  font-style: normal;
  font-weight: 700;
  font-size: 16.5px;
}

.res-block {
  margin-bottom: 30px;
}

.res-block h6 {
  font-family: Hind;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.show-res {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.show-res.through {
  display: flex;
  justify-content: space-between;
}

.show-res .t-box img {
  height: auto;
  max-width: 100%;
  max-height: 120px;
}

.show-res.through {
  display: flex;
  justify-content: space-between;
}

.img-box.big-box a.img-area {
  height: 185px;
  display: flex;
  text-align: center;
  background: #13192b;
  align-items: center;
  justify-content: center;
}

.img-box.big-box a.img-area img {
  max-width: 100%;
  height: auto;
  width: auto;
  max-height: 100%;
}

a.img-area img {
  max-width: 100%;
}

.show-res .t-box a.img-area {
  min-height: 80px;
  display: block;
  background: #13192b;
  margin-bottom: 15px;
  line-height: 0;
  text-align: center;
}

.show-res .t-box p {
  font-size: 16px;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.t-box {
  margin-right: 10px;
  background: #324271;
  box-shadow: 0px 0px 1px #59000000;
  border-radius: 4px;
  padding-bottom: 1px;
  overflow: hidden;
  text-align: center;
}

.show-res .t-box.empty a.img-area {
  min-height: 120px;
  line-height: 120px;
  background-color: #44285D;
}

.show-res .t-box.empty {
  background: #554266;
}

.quick-img-preview .t-box img {
  max-height: 170px !important;
}

.show-res.quick-img-preview {
  justify-content: flex-start !important;
}

.styles-module_carousel-arrow__26sRw{
  display: none !important;
}

/**Modal*/
table.thorough-table-modal {
  margin: 10px 0px;
  width: 100%;
  padding: 0;
  border-spacing: 0;
  /* Removes the cell spacing via CSS */
  border-collapse: collapse;
  overflow: hidden;
  font-family: 'Hind';
  font-size: 16px;
  padding: 16px 0 0;
  flex: 0 0 48%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

table.thorough-table-modal tr {
  margin: 0;
  padding: 0;
}

table.thorough-table-modal tr td {
  padding: 11px 15px;
  color: black;
  margin: 0;
  text-align: center;
}

table.thorough-table-modal tr td:first-child {
  text-align: left;
}

table.thorough-table-modal thead th:first-child {
  text-align: left;
}

table.thorough-table-modal thead th {
  background-color: #27396D;
  color: #fff;
  text-align: center;
  padding: 12px 15px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
}

table.thorough-table-modal tr:nth-child(odd) td {
  background-color: #F2F2F2;
}

table.thorough-table-modal tr:nth-child(even) td {
  background-color: #FFFFFF;
}

table.thorough-table-modal tr.total td {
  background: #112B4A;
  font-style: normal;
  font-weight: 700;
  font-size: 16.5px;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.img-box-modal {
  display: flex;
  justify-content: center;
}
.img-box-modal img{
  width: 100%;
  max-width: 300px;
}

@media only screen and (max-width: 600px) {
  .show-res .t-box img {
      max-height: 80px;
  }
}

@media only screen and (max-width: 850px) {

  .select-result {
      flex-direction: column;
      align-items: stretch;
  }

  .result-item {
    margin-bottom: 30px;
  }

  .select-result {
      margin-bottom: 20px;
  }
}